var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { class: _vm.$style.companyName }, [_vm._v("2段階認証設定")]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("section", { staticClass: "card" }, [
          _vm._m(0),
          _c("div", { staticClass: "card-body" }, [
            _vm.loading
              ? _c(
                  "div",
                  { staticClass: "text-center" },
                  [_c("a-spin", { attrs: { tip: "Loading..." } })],
                  1
                )
              : _vm._e(),
            !_vm.loading
              ? _c(
                  "div",
                  [
                    _c(
                      "a-form-model",
                      {
                        ref: "ruleForm",
                        attrs: {
                          model: _vm.form,
                          rules: _vm.rules,
                          "label-col": _vm.labelCol,
                          "wrapper-col": _vm.wrapperCol
                        }
                      },
                      [
                        _c(
                          "a-form-model-item",
                          {
                            ref: "is_google2fa",
                            attrs: { label: "2段階認証", prop: "is_google2fa" }
                          },
                          [
                            _c(
                              "a-radio-group",
                              {
                                model: {
                                  value: _vm.form.is_google2fa,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "is_google2fa", $$v)
                                  },
                                  expression: "form.is_google2fa"
                                }
                              },
                              [
                                _c("a-radio", { attrs: { value: 0 } }, [
                                  _vm._v("無効")
                                ]),
                                _c("a-radio", { attrs: { value: 1 } }, [
                                  _vm._v("有効")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          { attrs: { "wrapper-col": { span: 14, offset: 4 } } },
                          [
                            _c(
                              "a-button",
                              {
                                class: _vm.$style.filledBtn,
                                attrs: { type: "primary" },
                                on: { click: _vm.onSubmit }
                              },
                              [
                                _vm._v(
                                  "\n                  更新\n                "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.is2FA
                      ? _c(
                          "p",
                          { staticClass: "text-right" },
                          [
                            _c(
                              "a-button",
                              {
                                class: _vm.$style.filledBtn,
                                attrs: { type: "primary" },
                                on: { click: _vm.reset2FA }
                              },
                              [
                                _vm._v(
                                  "\n                2段階認証を初期化\n              "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      ]),
      _c("div", { staticClass: "col-lg-12" }, [
        _c("section", { staticClass: "card" }, [
          _vm._m(1),
          _c("div", { staticClass: "card-body" }, [
            _vm.loading
              ? _c(
                  "div",
                  { staticClass: "text-center" },
                  [_c("a-spin", { attrs: { tip: "Loading..." } })],
                  1
                )
              : _vm._e(),
            !_vm.loading
              ? _c("div", [
                  _vm.is2FA
                    ? _c("p", { staticClass: "text-center" }, [
                        _c("img", { attrs: { src: _vm.qrImg } }),
                        _c("br"),
                        _vm._v(
                          "\n              スマートフォンアプリのGoogle認証システムを立ち上げて、「QRコードをスキャン」から、こちらを読み込んでください。\n            "
                        )
                      ])
                    : _vm._e(),
                  !_vm.is2FA
                    ? _c("p", [
                        _vm._v(
                          "\n              2段階認証が設定されていません。\n            "
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("2段階認証設定")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("Google 認証システム 初期設定 QRコード")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }