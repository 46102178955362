<template>
  <div>
    <h1 :class="$style.companyName">2段階認証設定</h1>
    <div class="row">
      <div class="col-lg-12">
        <section class="card">
          <div class="card-header">
            <h2>2段階認証設定</h2>
          </div>
          <div class="card-body">
            <div v-if="loading" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!loading">
              <a-form-model
                ref="ruleForm"
                :model="form"
                :rules="rules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item ref="is_google2fa" label="2段階認証" prop="is_google2fa">
                  <a-radio-group v-model="form.is_google2fa">
                    <a-radio :value="0">無効</a-radio>
                    <a-radio :value="1">有効</a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                  <a-button type="primary" @click="onSubmit" :class="$style.filledBtn">
                    更新
                  </a-button>
                </a-form-model-item>
              </a-form-model>
              <p class="text-right" v-if="is2FA">
                <a-button type="primary" @click="reset2FA" :class="$style.filledBtn">
                  2段階認証を初期化
                </a-button>
              </p>
            </div>
          </div>
        </section>
      </div>

      <div class="col-lg-12">
        <section class="card">
          <div class="card-header">
            <h2>Google 認証システム 初期設定 QRコード</h2>
          </div>
          <div class="card-body">
            <div v-if="loading" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!loading">
              <p class="text-center" v-if="is2FA">
                <img :src="qrImg"><br>
                スマートフォンアプリのGoogle認証システムを立ち上げて、「QRコードをスキャン」から、こちらを読み込んでください。
              </p>
              <p v-if="!is2FA">
                2段階認証が設定されていません。
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import QRCode from 'qrcode'

export default {
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },

      loading: true,
      is2FA: false,
      qrImg: '',
      form: {
        is_google2fa: 0,
      },
      rules: {
        is_google2fa: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
      },
    }
  },
  created() {
    const result = Vue.prototype.$api.send('get', 'user')
    result.then(response => {
      this.loading = false
      this.is2FA = response.is_google2fa
      this.form.is_google2fa = this.is2FA
      if (this.is2FA) {
        this.getQRImg()
      }
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': ユーザー情報の取得に失敗しました。',
        })
        this.loading = false
      })
  },
  methods: {
    onSubmit() {
      console.log('onsubmi')
      const _this = this
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          var formData = this.form
          const send = Vue.prototype.$api.send('put', 'user/update/2fa', formData)
          send.then(response => {
            console.log(response)
            if (formData.is_google2fa) {
              _this.is2FA = 1
              this.getQRImg()
            } else {
              _this.is2FA = 0
            }
            _this.$notification['success']({
              message: '更新しました。',
            })
          })
            .catch(error => {
              if (error.status === 400 || error.status === 403) {
                _this.$notification['error']({
                  message: error.data.data,
                })
              } else {
                for (var key in error.data.errors) {
                  _this.$refs[key].validateState = 'error'
                  _this.$refs[key].validateMessage = error.data.errors[key]
                }
              }
              console.log(error)
            })
        }
      })
    },
    reset2FA() {
      const send = Vue.prototype.$api.send('post', 'user/reset_2fa')
      send.then(response => {
        this.$notification['success']({
          message: '2段階認証を初期化しました。',
        })
        this.$router.push('/users/' + this.$route.params.id)
      })
        .catch(error => {
          if (error.status === 400 || error.status === 403 || error.status === 404) {
            this.$notification['error']({
              message: error.data.data,
            })
          }
          console.log(error)
        })
    },
    getQRImg() {
      if (this.is2FA) {
        this.loading = true
        const result = Vue.prototype.$api.send('get', 'user/show/2fa_qr')
        result.then(response => {
          this.loading = false
          this.loading = false
          QRCode.toDataURL(response, { width: 150 })
            .then(code => {
              // コード(URLスキーム)が生成されるので、imgタグのsrc=の中に値を入れましょう
              this.qrImg = code
            })
            .catch(err => {
              console.error(err)
            })
        })
          .catch(error => {
            this.$notification['error']({
              message: error.status + ': QRコードの取得に失敗しました。',
            })
            this.loading = false
          })
      }
    },
  },
}
</script>

<style lang="scss" module>
@import "../style.module.scss";
</style>
